import { PageHeader } from 'components/common/PageHeader';
import { Card } from 'react-bootstrap';

export const GettingsStarted = () => (
  <>
    <PageHeader title="Getting Started" description="" className="mb-3" />

    <Card className="mb-3">
      <Card.Header>
        <h5>Installation</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <ul>
          <li>Login to FlySim Control Center - https://app.passcargo.app</li>
          <li>Go to Addons section and click on PassCargo Desktop</li>
          <li>Download and install setup executable file</li>
        </ul>
      </Card.Body>
    </Card>

    <Card className="mb-3">
      <Card.Header>
        <h5>Add Stream Overlay to OBS Studio</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <p>
          Launch PassCargo Desktop. Click on the option "Stream Overlay" on dashboard screen. Copy url from the browser.
        </p>
        <p>
          Just go to OBS Studio workspace and add{' '}
          <strong>
            <i>Browser</i>
          </strong>{' '}
          layer into your scene. Enter overlay url into the seetings and make sure that overlay is streched to screen.
        </p>
        <code>http://localhost:30500/#/streaming</code>
      </Card.Body>
    </Card>

    <Card className="mb-3">
      <Card.Header>
        <h5>Stream Overlay Configuration</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <p>
          You can find a lot of configuration options in{' '}
          <strong>
            <i>Streaming</i>
          </strong>{' '}
          section
        </p>
      </Card.Body>
    </Card>

    <Card>
      <Card.Header>
        <h5>Flight planning using Simbrief</h5>
      </Card.Header>
      <Card.Body className="bg-light">
        <p>Make sure that you set Simbrief user id in General settings in the application.</p>
      </Card.Body>
    </Card>
  </>
);
