import efb from 'assets/img/efb.png';
import { PageHeader } from 'components/common/PageHeader';
import pkg from '../../../package.json';

export const DesktopComponent = () => {
  return (
    <>
      <PageHeader
        title="PassCargo Desktop"
        description="Plan, track, log your flights. Works only on Windows and compatible with Microsoft Flight Simulator, X Plane 11/12 and Prepar3D"
        className="mb-3"
      />
      <div className="card overflow-hidden" style={{ width: '40rem' }}>
        <div className="card-img-top">
          <img className="img-fluid" src={efb} alt="PassCargo Desktop" />
        </div>
        <div className="card-body">
          <h5 className="card-title">v25.1.0 / Feb 8th, 2025</h5>
          <ul>
            <li>Introduced dark theme</li>
            <li>Introduced pilot / attendants announcements</li>
            <li>Re-designed settings (added ability to set volume and announcements)</li>
            <li>Added integration with GSX PRO for boarding and deboarding procedures</li>
            <li>Re-design active flight view</li>
            <li>Added passengers to the flight (simulate)</li>
            <li>Added cargo flights processing</li>{' '}
          </ul>
          <a className="btn btn-primary btn-sm" href={pkg.downloadUrl} target="_blank">
            <span className="fas fa-windows me-1" data-fa-transform="shrink-3"></span>Download
          </a>
        </div>
      </div>
    </>
  );
};
