import { FlightsMap } from 'components/livemap/FlightsMap';
import { loadStatistic, loadSummary } from 'features/account';
import { useEffect } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { accountTransport } from 'transport';
import { StatByAirline } from './StatByAirline';
import { StatByAirplane } from './StatByAirplane';
import { StatByOrigin } from './StatByOrigin';
import { TotalFlights } from './TotalFlights';
import { TraveledDistance } from './TraveledDistance';

export const Welcome = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    accountTransport.loadSummary().then((payload) => dispatch(loadSummary(payload)));
    accountTransport.loadStatistic().then((payload) => dispatch(loadStatistic(payload)));
  }, []);

  return (
    <>
      {searchParams.has('flightCanceled') && <Alert variant="success">Flight has been canceled</Alert>}
      <Row className="g-3 mb-3">
        <Col md={4}>
          <TotalFlights />
        </Col>
        <Col md={4}>
          <TraveledDistance />
        </Col>
        <Col md={4}></Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={6} lg={4}>
          <StatByAirplane />
        </Col>
        <Col md={6} lg={4}>
          <StatByAirline />
        </Col>
        <Col md={6} lg={4}>
          <StatByOrigin />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <FlightsMap />
        </Col>
      </Row>
      {/* <Row className="g-3 mb-3">
        <Col lg={12}>
          <LastFlight />
        </Col>
      </Row> */}
    </>
  );
};
